import * as React from "react"
import parse from 'html-react-parser';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button, Container, Row, Col, Form } from "react-bootstrap" 
import "./FormBlock.scss"

import RegisterForm from "../../forms/register-form" 
// markup
const RegisterBlock = (props) => {
    return (
        <React.Fragment>
            <section className={`form-section components--Home--FormBlock--FormBlock ${props.count == 1 ? 'form-main-one' : ''}`}>
                <Container>
                    <Row className="main-row">
                        <Col lg="12">
                            <div className="register-interest-form-block">
                                <div className="valuation-block">

                                    <ScrollAnimation animateIn="fadeInUp">
                                        <h2>Register with us to get the latest developer updates</h2>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                    </ScrollAnimation>

                                </div>
                                <div className="register-interest-form">
                                    <ScrollAnimation animateIn="fadeInUp">
                                        <RegisterForm />
                                    </ScrollAnimation>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default RegisterBlock
