import React, { useEffect } from "react";
import ReactPlayer from 'react-player'
import {
    isIOS,
    isMobileOnly,
    isBrowser,
    isMobile
} from "react-device-detect";
const PlayVideo = (props) => {
    return (
        <React.Fragment>

            <ReactPlayer
                url={props.url}
                controls={true}
                frameborder="0"
                muted={true}
                autoplay={true}
                playing={true}
                loop={true}
                width='100%'
                height='600px'
                previewTabIndex={0} light={false} />
        </React.Fragment>
    )
};

export default PlayVideo;