import React, { useState, useEffect } from "react"

import { Form } from "react-bootstrap"

import InputField from './elements/input'
import SelectField from './elements/select'
import TextAreaField from './elements/textarea'
import CheckboxField from './elements/checkbox'
import ButtonField from './elements/button'
import HtmlBox from './elements/html'
import ReCaptchaBox from './elements/recaptcha'
import { postFormData } from "./api/Api"
// import UserAgent from 'user-agents';
import { getIpAddress } from "../common/site/functions"
import { getAllCookie } from "../common/site/cookie";
import axios from "axios"
import * as qs from "query-string"

import $ from "jquery"

function SendFriendForm(props) {
  // const userAgent = new UserAgent();
  const [validated, setValidated] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [showthankyou, setThankyou] = useState(false);
  const [ipAddress, setIpAddress] = useState("");
  const [formvalues, setFormvalues] = useState("");

  const [token, setToken] = useState("");

  const myRef = React.createRef();

  const recaptchaRef = React.createRef();

  const fields = ([
    {
      element: "config",
      formname: "Send property to a friend",
      error_text: "Highlighted fields are required | invalid",
      success_text: "Thank you for suggesting a property to your friend. We've sent the details to your friend.",
      email_temp_user: "send_to_friend_user",
      email_temp_admin: "send_to_friend_admin",
      email_subject_user: `You Share a Property with ${process.env.GATSBY_SITENAME} `,
      email_subject_admin: "Send property to a friend",
      email_server_func: "send_to_friend",
      event_tracking: "send_to_friend",
      page_url: "/property-wishlist"
    },
    {
      grpmd: "12",
      label: "Name*",
      placeholder: "Your name *",
      name: "name",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Email*",
      placeholder: "Please add a valid email*",
      name: "email",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Friend's Name*",
      placeholder: "Your friend's name*",
      name: "friendname",
      type: "text",
      element: "input",
      required: true,
      patternchk: "^[-a-zA-Z0-9-()]+(\\s+[-a-zA-Z0-9-()]+)*$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Friend's Email*",
      placeholder: "Please add a valid email*",
      name: "friendemail",
      type: "email",
      element: "input",
      required: true,
      patternchk: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,}$",
      labelClass: "content_b-18",
    },
    {
      grpmd: "12",
      label: "Message",
      placeholder: "Write your message here",
      name: "message",
      element: "textarea",
      class: "mb-4",
      rows: "2"
    },
    {
      grpmd: "12",
      name: "SEND THIS PROPERTY",
      type: "submit",
      element: "button",
      value: "submit",
      step: "col-md-12 text-center",
      class: "btn-secondary",
    },
    {
      text: 'By clicking Submit, you agree to our <a href="/terms-and-conditions"> Terms &amp; Conditions </a> and <a href="/privacy-policy"> Privacy Policy </a>',
      element: "html",
      class: "apply"
    },
    {
      element: "captcha",
      class: "py-2",
      captchaRef: recaptchaRef
    },
  ]);

  const handlechange = event => {
    // remove initial empty spaces
    event.target.value = event.target.value.trimStart()
  }

  useEffect(() => {
    getIpAddress((ip) => {
      setIpAddress(ip)
    })
    if (token !== '') {

      const processFromData = async () => {


        formvalues['g-recaptcha-response'] = token;
        var gtm_client_id = '';
        var user_agent = '';
        if (typeof window !== 'undefined') {
          user_agent = window.navigator.userAgent
          if (typeof window.ga !== 'undefined') {
            gtm_client_id = window.ga.getAll()[0].get('clientId');

          }
        }
        let formData = new FormData();
        formvalues['extra'] = JSON.stringify({
          message: formvalues.message,
          name: formvalues.name
        });
        var url = typeof window !== 'undefined' ? window.location.href : ''
        formvalues['name'] = formvalues.name;
        formvalues['email_subject_user'] = fields[0].email_subject_user;
        formvalues['email_subject_admin'] = fields[0].email_subject_admin;
        formvalues['referrer'] = url;
        formvalues['google_analytics_client_id'] = gtm_client_id;
        formvalues['user_agent'] = user_agent;
        formvalues['ip_address'] = ipAddress;
        formvalues['brand_id'] = process.env.GATSBY_STRAPI_BRAND_ID;
        formvalues['address'] = {
          "stno": props.subject.address ? props.subject.house_name_number : '',
          "street": props.subject.address ? props.subject.street : '',
          "district": props.subject.address ? props.subject.district : '',
          "post_town": props.subject.address ? props.subject.town : '',
          "county": props.subject.address ? props.subject.county : '',
          "postcode": props.subject.address ? props.subject.postcode : ''
        }
        formData.append('data', JSON.stringify(formvalues));

        postFormData(formData).then(async apiRes => {
          window.grecaptcha.reset()
          // lets send mail

          await window.fetch(`${process.env.GATSBY_CLOUD_URL}/api/send-friend-form`, {
            method: `POST`,
            mode: "no-cors",
            headers: {
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: qs.stringify(formvalues),
          })
        });
        // tracking event
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event': 'formSubmit',
          'formType': 'form-' + fields[0].event_tracking,
          'formId': 'form-' + fields[0].event_tracking,
          'formName': fields[0].formname,
          'formLabel': fields[0].formname
        });

        setShowerror(false);
        setThankyou(true);
        myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })

      }
      processFromData();

    }
  }, [token]);

  const handleonVerify = token => {
    console.log("captcha verified");
    setToken(token);
  };

  const handleSubmit = event => {

    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setShowerror(true);
      setValidated(true);
      setThankyou(false);
      myRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
    else {

      event.preventDefault();
      setShowerror(false);
      const formsdata = (event.target);
      const json = {}
      Object.keys(formsdata).map(key => (
        json[formsdata[key].name] = (formsdata[key].checked) ? 'yes' : formsdata[key].value
      ))

      json['email_temp_user'] = fields[0].email_temp_user;
      json['email_temp_admin'] = fields[0].email_temp_admin;
      json['formname'] = fields[0].formname;
      json['property_id'] = props.subject.property_id ? props.subject.property_id : '';
      json['property_url'] = props.subject.page_url ? props.subject.page_url : '';
      json['crm_id'] = props.subject.crm_id ? props.subject.crm_id : '';
      json['price'] = props.subject.price ? props.subject.price : '';
      json['type'] = props.subject.type ? props.subject.type : '';
      json['property_title'] = props.subject.property_title ? props.subject.property_title : '';
      json['property_img'] = props.subject.property_img ? props.subject.property_img : '';
      // json['property_list'] = props.subject.properties ? props.subject.properties : '';

      json['g-recaptcha-response'] = token;

      setFormvalues(json);

      recaptchaRef.current.execute();

      setValidated(false);

      // reset form
      const form = event.target
      form.reset();
    }
  };


  var url = typeof window !== 'undefined' ? window.location.href : ''
  var cookieData = getAllCookie();
  return (
    <div className="form stbform valuation-main share-to-friend">
      <div ref={myRef} />



      <Form className="contact-form-page" name={fields[0].formname} action="/thank-you/" method="post" noValidate validated={validated} onSubmit={handleSubmit}>

        {showerror && <div className="alert alert-danger alert-error">
          <p>{fields[0].error_text}</p>
        </div>}

        {showthankyou && <div className="alert alert-success">
          <p>{fields[0].success_text}</p>
        </div>}
        <Form.Row>
          <input type="hidden" name="form_name" value={fields[0].formname} />
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form_type_id" value="13" />
          <input type="hidden" name="form_page" value={url} />
          <input type="hidden" name="brand_id" value={process.env.GATSBY_STRAPI_BRAND_ID} />
          <input type="hidden" name="utm_source" value={cookieData.utm_source} />
          <input type="hidden" name="utm_campaign" value={cookieData.utm_campaign} />
          <input type="hidden" name="utm_term" value={cookieData.utm_term} />
          <input type="hidden" name="utm_content" value={cookieData.utm_content} />
          <input type="hidden" name="utm_medium" value={cookieData.utm_medium} />
          <div className="step-block">
            {fields.map((field, index) => {
              if ("input" === field.element) {
                return (
                  <InputField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    type={field.type}
                    fieldClass={field.class}
                    className={'snd-frnd'}
                    placeholder={field.placeholder}
                    label={field.label}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    pattern={field.patternchk}
                    handlechange={handlechange}
                    defaultValue={field.defaultValue}
                  />
                );
              }
              if ("select" === field.element) {
                return (
                  <SelectField
                    name={field.name}
                    grpmd={field.grpmd}
                    label={field.label}
                    ref={field.ref}
                    required={field.required}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    values={field.values}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                    componentprops={props}
                  />
                );
              }
              if ("textarea" === field.element) {
                return (
                  <TextAreaField
                    name={field.name}
                    grpmd={field.grpmd}
                    ref={field.ref}
                    rows={field.rows}
                    fieldClass={field.class}
                    className={'snd-frnd'}
                    placeholder={field.placeholder}
                    label={field.label}
                    required={field.required}
                    key={`${field.element}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("checkbox" === field.element) {
                return (
                  <CheckboxField
                    name={field.name}
                    ref={field.ref}
                    value={field.value}
                    fieldClass={field.class}
                    placeholder={field.placeholder}
                    required={field.required}
                    key={`${field.name}~${index}`}
                    handlechange={handlechange}
                  />
                );
              }
              if ("html" === field.element) {
                return (
                  <HtmlBox
                    text={field.text}
                    fieldClass={field.class}
                    key={`${field.element}~${index}`}
                  />
                );
              }
              if ("captcha" === field.element) {
                return (
                  <ReCaptchaBox
                    fieldClass={field.class}
                    captRef={field.captchaRef}
                    key={`${field.element}~${index}`}
                    handleonVerify={handleonVerify}
                  />
                );
              }
              if ("button" === field.element) {
                return (
                  <Form.Group>
                    <ButtonField
                      name={field.name}
                      fieldClass={field.class}
                      type={field.type}
                      value={field.value}
                      key={`${field.element}~${index}`}
                    />
                  </Form.Group>
                );
              }
            })
            }
          </div></Form.Row>
      </Form>
    </div>
  );
}


const SendFriendFormPage = (props) => (
  <SendFriendForm subject={props} />
)

export default SendFriendFormPage
