import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';

import "./TopBar.scss"
const TopBar = () => {
    return (
        <React.Fragment>
            <section className="top-menu">
                <Container>
                    <Row>
                        <Col className=" menu-bar d-flex align-items-center">
                        <a href={'javascript:void(0)'} onClick={(e) => window.history.back(-1)}>
                            <i className="arrow-left"></i>
                        </a>
                        <a href={'javascript:void(0)'} className="sm-text" onClick={(e) => window.history.back(-1)}>Back to search results</a>
                        </Col>
                    </Row>
                    </Container>                
            </section>
    
        </React.Fragment>
    )
}
export default TopBar
